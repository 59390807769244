/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import focusLock from 'dom-focus-lock';

// Close navigation on backdrop click or escape key
const navigationToggler = document.getElementById('nav'), navigationWrapper = document.querySelector('header .nav-wrapper'), navigation = document.querySelector('header .nav-wrapper nav');
if (navigationToggler && navigationWrapper && navigation) {
	var closeToggle = navigation.querySelector('.close');
	const toggle = checked => {
		navigationToggler.checked = checked;
		navigationWrapper.setAttribute('aria-expanded', checked);

		if (checked) {
			focusLock.on(navigation);

			// Focus close toggle on open
			if (closeToggle) {
				closeToggle.focus();
			}
		} else {
			focusLock.off(navigation);
		}
	};

	navigationWrapper.addEventListener('click', e => {
		if (!navigation.contains(e.target)) {
			toggle(false);
		}
	});

	document.addEventListener('keydown', e => {
		if (e.keyCode === 27 && navigationToggler.checked) {
			toggle(false);
		}
	});

	// Fix for disabled input Enormail
	const enormailLink = document.getElementById('enormail-link');
	if (enormailLink) {
		enormailLink.addEventListener('click', e => {
			if (navigationToggler.checked) {
				toggle(false);
			}
		});
	}

	// Keep aria-expanded in-sync with checked state
	navigationToggler.addEventListener('change', () => {
		toggle(navigationToggler.checked);
	});

	toggle(navigationToggler.checked);
}

// Inner menu dropdown handling
var togglers = document.querySelectorAll(".drop-down-toggler")
togglers.forEach(el => {
	el.addEventListener('click', () => {
		if (document.querySelector("#" + el.htmlFor).checked) {
			el.parentElement.classList.remove("background-shade");
		}
		else {
			el.parentElement.classList.add("background-shade");
		}
	});
});

// Close dropdown on click
document.querySelectorAll('dl.dropdown-list > dt').forEach(el => {
	el.addEventListener('click', () => {
		el.parentElement.classList.add('clicked');
		el.parentElement.classList.toggle('open');
	});

	el.addEventListener('blur', () => {
		// Timeout is required for iOS
		setTimeout(() => {
			el.parentElement.classList.remove('clicked');
			el.parentElement.classList.remove('open');
		}, 0);
	});

	// Prevent mousedown in next sibling (dd) to prevent click event to bubble to blur
	el.nextElementSibling.addEventListener('mousedown', e => {
		e.preventDefault();
	});

	el.addEventListener('keydown', e => {
		// Space and Enter
		if (e.keyCode === 13 || e.keyCode === 32) {
			el.click();
			e.preventDefault();
		}
	});
});

// Masonry
const masonry = document.getElementById('main');
var masonryElement = null;
if (masonry) {
	CreateMasonry();
}

async function CreateMasonry() {
	const Masonry = (await import(/* webpackChunkName: "masonry-layout" */ 'masonry-layout')).default;

	// Masonry for download block
	document.querySelectorAll('.masonry-columns').forEach(el => {
		var msnry = new Masonry(el, {
			// options
			itemSelector: '.masonry-column',
			percentPosition: true
		});
	});

	masonryElement = new Masonry(masonry, {
		percentPosition: true,
		itemSelector: '.sizer, section, .image-block, .overview-item-half'
	});

	var portalBtn = document.querySelector("#register, #login");
	if (portalBtn) {
		portalBtn.addEventListener('click', function (event) {
			setTimeout(function () {
				masonryElement.layout();
			}, 150);
		});
	}
}

// Google maps
const maps = document.querySelector('.maps');
if (maps) {
	(async function () {
		var apiKey = maps.getAttribute('data-api-key'),
			dataGeolocations = maps.getAttribute('data-geolocations').split(';');

		const loadGoogleMapsApi = (await import(/* webpackChunkName:"load-google-maps-api" */ 'load-google-maps-api')).default;

		loadGoogleMapsApi({ key: apiKey }).then(function (googleMaps) {
			var bounds = new googleMaps.LatLngBounds();

			var map = new googleMaps.Map(document.querySelector('.maps'), {
				zoom: 17,
				zoomControl: false,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				styles: [{ "featureType": "administrative", "elementType": "labels.text.fill", "stylers": [{ "color": "#444444" }] }, { "featureType": "landscape", "elementType": "all", "stylers": [{ "color": "#f2f2f2" }] }, { "featureType": "poi", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "road", "elementType": "all", "stylers": [{ "saturation": -100 }, { "lightness": 45 }] }, { "featureType": "road.highway", "elementType": "all", "stylers": [{ "visibility": "simplified" }] }, { "featureType": "road.arterial", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "water", "elementType": "all", "stylers": [{ "color": "#d2e7e7" }, { "visibility": "on" }] }]
			});

			var image = {
				url: require('../images/maps-marker.png').default,
				size: new googleMaps.Size(170, 85),
				scaledSize: new googleMaps.Size(170, 85),
				origin: new googleMaps.Point(0, 0),
				anchor: new googleMaps.Point(85, 85)
			};

			for (var i = 0; i < dataGeolocations.length; i++) {
				var latLng = dataGeolocations[i].split(',');

				var marker = new googleMaps.Marker({
					position: {
						lat: parseFloat(latLng[0]),
						lng: parseFloat(latLng[1])
					},
					map: map,
					icon: image,
					optimized: false
				});

				bounds.extend(marker.position);
			}

			// Set zoom bounds are fit
			var zoomChangeBoundsListener;
			googleMaps.event.addListener(map, 'zoom_changed', function () {
				zoomChangeBoundsListener =
					googleMaps.event.addListener(map, 'bounds_changed', function (event) {
						if (this.getZoom() > 15 && this.initialZoom === true) {
							this.setZoom(15);
							this.initialZoom = false;
						}
						googleMaps.event.removeListener(zoomChangeBoundsListener);
					});
			});

			map.initialZoom = true;
			map.fitBounds(bounds, { bottom: 320, top: 120 });
		});


	})();
}

//Filter
const productFilter = document.getElementById('product-filter');
const overview = document.getElementById('overview');
if (productFilter && overview) {

	// Get all the inputs
	var inputs = productFilter.querySelectorAll('input');
	var reset = document.getElementById('reset');
	inputs.forEach(el => {

		// Add on change event to inputs
		el.addEventListener("change", async function () {

			// Set reset button class
			reset.classList.remove("is-hidden");

			// Get the checked inputs from the form
			const data = new URLSearchParams(new FormData(productFilter));

			// Get new overview
			fetch(window.location.pathname, {
				method: "post",
				body: data,
				headers: {
					'X-Requested-With': 'XMLHttpRequest'
				}
			})
				.then(response => response.text())
				.then(data => {
					overview.innerHTML = data;

					// Redo masonry
					if (masonryElement) {
						masonryElement.destroy();
						CreateMasonry();
					}
				});

			// Update the url
			if (history.pushState) {

				if (data.toString() === "") {
					window.history.replaceState({}, '', `${location.pathname}`);
				}
				else {
					window.history.replaceState({}, '', `${location.pathname}?${data}`);
				}
			}
		});
	});

	// Reset
	reset.addEventListener('click', function (e) {
		inputs.forEach(el => {
			el.checked = false;
		});

		// Get the checked inputs from the form
		const data = new URLSearchParams(new FormData(productFilter));

		// Get new overview
		fetch(window.location.pathname, {
			method: "post",
			body: data,
			headers: {
				'X-Requested-With': 'XMLHttpRequest'
			}
		})
			.then(response => response.text())
			.then(data => {
				overview.innerHTML = data;

				// Redo masonry
				if (masonryElement) {
					masonryElement.destroy();
					CreateMasonry();
				}
			});

		// Update the url
		if (history.pushState) {

			if (data.toString() === "") {
				window.history.replaceState({}, '', `${location.pathname}`);
			}
			else {
				window.history.replaceState({}, '', `${location.pathname}?${data}`);
			}
		}

		// Set reset button class
		reset.classList.add("is-hidden");

		e.preventDefault();
	});
}

// Scroll to register/login blocks on error reload
document.addEventListener("DOMContentLoaded", function () {
	var registerBlock = document.querySelector(".registration-block");
	if (registerBlock) {
		registerBlock.querySelectorAll(".field-wrapper").forEach(fieldWrapper => {
			var innerSpan = fieldWrapper.querySelector("span");
			if (innerSpan.classList.contains("field-validation-error")) {
				registerBlock.scrollIntoView();
			}
		});
	}

	var loginBlock = document.querySelector(".login-block");
	if (loginBlock) {
		var innerDiv = loginBlock.querySelector(".validation-summary-errors");
		if (innerDiv) {
			loginBlock.scrollIntoView();
		}
	}
});